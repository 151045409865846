import { Button, Card, Grid, Paragraph } from "@hexa-ui/components";
import { ArrowRight, Clipboard, Download, Edit2 } from "@hexa-ui/icons";
import { TypeToast } from "admin-portal-shared-services";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import CustomToast from "../../components/CustomToast/CustomToast";
import ExperimentValidationDialog from "../../components/DialogConfig/ExperimentValidationDialog/ExperimentValidationDialog";
import EnterResultsDialog from "../../components/EnterResultsDialog/EnterResultsDialog";
import EstimatedDateDialog from "../../components/EstimatedDateDialog/EstimatedDateDialog";
import AudienceSelect from "../../components/ExperimentCommon/AudienceSelect/AudienceSelect";
import BasicInformation from "../../components/ExperimentCommon/BasicInformation/BasicInformation";
import ExperimentSchedule from "../../components/ExperimentCommon/ExperimentSchedule.tsx/ExperimentSchedule";
import ExperimentStatus from "../../components/ExperimentCommon/ExperimentStatus.tsx/ExperimentStatus";
import Metrics from "../../components/ExperimentCommon/Metrics/Metrics";
import Variants from "../../components/ExperimentCommon/Variants/Variants";
import ExperimentResults from "../../components/ExperimentResults/ExperimentResults";
import ExportFileDialog from "../../components/ExportFileDialog/ExportFileDialog";
import CustomPageHeader from "../../components/molecules/CustomPageheader/CustomPageHeader";
import { useSteppers } from "../../context/StepperContext/stepperProvider";
import { useToastConfiguration } from "../../context/ToastConfigContext/toastConfigProvider";
import { useGetValidationAudiencePocIdsQuery } from "../../hooks/queries/audiences/useGetValidationAudiencePocIdsQuery/useGetValidationAudiencePocIdsQuery";
import { useExportExperimentQuery } from "../../hooks/queries/experiments/useExportExperimentQuery/useExportExperimentQuery";
import { useGetSingleExperimentQuery } from "../../hooks/queries/experiments/useGetSingleExperimentQuery";
import { useStopExperimentValidation } from "../../hooks/queries/experiments/useStopExperimentValidation/useStopExperimentValidation";
import useResizeObserverErrorHandler from "../../hooks/useResizeObserverErrorHandler/useResizeObserverErrorHandler";
import useToastConfig from "../../hooks/useToast/useToastConfig";
import { isNotItEmptyOrNull } from "../../services/utils";
import {
  resetExperimentMutation,
  setExperimentMutation,
} from "../../store/ducks/ExperimentMutation/ExperimentMutationDuck";
import "../../style.css";
import { useCustomHistory } from "../../utils/routes";
import {
  SET_TIMEOUT_TOAST_DURATION,
  prePopulateExperimentMutation,
} from "../ExperimentMutation/utils";
import { generateJsonFile } from "../ExperimentsList/utils/utils";
import { useDialog } from "./../../context/DialogContext/dialogProvider";
import { usePublishExperiment } from "./../../hooks/queries/experiments/usePublishExperiment";
import { usePublishExperimentValidation } from "./../../hooks/queries/experiments/usePublishExperimentValidation/usePublishExperimentValidation";
import { ExperimentStatusList } from "./../../types/experiment";
import { isValidExportResponse } from "./../../utils/utils";

const ExperimentDetails = (): JSX.Element => {
  useResizeObserverErrorHandler();
  const { Item } = Grid;

  const navigate = useCustomHistory();
  const { id: experimentId } = useParams();

  const {
    data: experiment,
    refetch: refetchGetExperimentById,
    isSuccess: isGetSingleExperimentQuerySuccess,
  } = useGetSingleExperimentQuery(experimentId);

  const [isLoading, setIsLoading] = useState(false);

  const dataPublish = usePublishExperiment(experimentId);

  const dataPublishExperimentValidation =
    usePublishExperimentValidation(experimentId);

  const dataStopPublisExperimentValidation =
    useStopExperimentValidation(experimentId);
  const dispatch = useDispatch();

  const { openDialog, closeDialog } = useDialog();

  const exportQuery = useExportExperimentQuery();

  const { toast, handleToast, handleCloseToast } = useToastConfig();

  const { handleToast: handleToastConfig } = useToastConfiguration();

  useEffect(() => {
    if (isGetSingleExperimentQuerySuccess) {
      const experimentMutationForm = prePopulateExperimentMutation(experiment);
      dispatch(setExperimentMutation(experimentMutationForm));
    }
  }, [isGetSingleExperimentQuerySuccess]);

  const enterResultsButton = (
    <Button
      icon={Clipboard}
      data-testid="button-enter-experiment-results"
      size="medium"
      type="button"
      leading
      className="mr-1"
      onClick={() => setEnterResultsOpen(true)}
      disabled={isLoading}
    >
      Enter results
    </Button>
  );

  const exportExperimentJSONFileButton = (
    <Button
      icon={Download}
      size="medium"
      type="button"
      leading
      className="mr-1"
      variant="secondary"
      data-testid="button-export-experiment"
      onClick={() => exportQuery.exportJson(experimentId)}
      disabled={isLoading}
    >
      <FormattedMessage id="experiment_details.buttons.export_json_file" />
    </Button>
  );
  const endExperiment = (
    <Button
      icon={ArrowRight}
      size="medium"
      type="button"
      leading
      className="mr-1"
      variant="primary"
      data-testid="button-end-experiment"
      onClick={() => setHandleModal(true)}
      disabled={isLoading}
    >
      <FormattedMessage id="experiment_details.buttons.end_experiment" />
    </Button>
  );

  const [isEstimatedEndDateDialogOpen, setIsEstimatedEndDateDialogOpen] =
    useState(false);
  const [estimatedEndDate, setEstimatedEndDate] = useState(new Date());

  const actionArea = () => {
    const status = experiment?.status + "";

    switch (status) {
      case "DRAFT":
        return (
          <div style={{ display: "flex" }}>
            {exportExperimentJSONFileButton}
            {/* {editButton} */}
          </div>
        );
      case "FINISHED":
        return experiment?.result ? (
          exportExperimentJSONFileButton
        ) : (
          <div style={{ display: "flex" }}>
            {exportExperimentJSONFileButton}
            {enterResultsButton}
          </div>
        );
      case "STARTED":
        return (
          <div style={{ display: "flex" }}>
            {exportExperimentJSONFileButton}
            {endExperiment}
          </div>
        );
      default:
        return exportExperimentJSONFileButton;
    }
  };

  const [handleModal, setHandleModal] = useState(false);
  const [enterResultsOpen, setEnterResultsOpen] = useState(false);

  const queryClient = useQueryClient();

  const handleCancelExportJSON = () => {
    queryClient.resetQueries({ queryKey: ["export-experiment"] });
  };
  const dataPocIds = useGetValidationAudiencePocIdsQuery();

  const handlePublishExperimentValidation = async () => {
    let isValidResponse: any = {};

    isValidResponse =
      await dataPublishExperimentValidation.mutateAsync(experimentId);

    if (
      isValidResponse &&
      (isValidResponse?.code?.includes("ERR_BAD_REQUEST") ||
        isValidResponse?.name?.includes("AxiosError"))
    ) {
      handleToastConfig({
        isOpened: true,
        type: TypeToast.ERROR,
        message: isValidResponse?.response?.data?.errors[0]?.message,
        duration: SET_TIMEOUT_TOAST_DURATION,
      });

      return;
    }

    setIsLoading(false);
    closeDialog();
    handleToastConfig({
      isOpened: true,
      type: TypeToast.SUCCESS,
      message:
        "Test version successfully sent. It will be reflected in the app in up to 6 hours.",
      duration: SET_TIMEOUT_TOAST_DURATION,
    });
    refetchGetExperimentById();
  };

  const handlePublishExperimentValidationDialog = async () => {
    openDialog(
      "Send test version?",
      "",
      <ExperimentValidationDialog pocIds={dataPocIds?.data} />,
      null,
      null,
      [
        {
          action: () => {
            closeDialog();
          },
          dialogActionText: "No, go back",
          dialogActionButtonType: "secondary",
        },
        {
          action: () => {
            handlePublishExperimentValidation();
          },
          dialogActionText: "Yes, send",
          dialogActionButtonType: "primary",
        },
      ],
      true,
      {
        width: "400px",
        fontFamily: "Work Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
      }
    );
    return;
  };

  const renderPublishButton = useCallback(() => {
    const isItInValidation =
      experiment?.status === ExperimentStatusList.IN_VALIDATION;
    if (
      isItInValidation ||
      (experiment?.status === ExperimentStatusList.DRAFT &&
        experiment?.variations?.length > 0 &&
        (experiment?.audiences?.length > 0 || experiment?.countryAsAudience))
    ) {
      return (
        <>
          {!isItInValidation && (
            <Button
              size="medium"
              variant="secondary"
              data-testid="publish-experiment-validation-button"
              type="button"
              onClick={() => handlePublishExperimentValidationDialog()}
              disabled={dataPublishExperimentValidation.isLoading}
              style={{ marginRight: "10px" }}
            >
              <FormattedMessage id="create_and_update_experiment.buttons.step4.publish_experiment_validation" />
            </Button>
          )}
          <Button
            size="medium"
            variant="primary"
            data-testid="publish-experiment-button"
            type="button"
            onClick={() => openPublishDialog()}
            disabled={isLoading}
          >
            <FormattedMessage id="create_and_update_experiment.buttons.step4.publish_experiment" />
          </Button>
        </>
      );
    }
  }, [isLoading, experiment]);

  const isValidExperimentPublishResponse = (data) => {
    if (
      data?.hasError ||
      data?.response?.name?.includes("AxiosError") ||
      data?.response?.status?.toString().startsWith("4") ||
      data?.response?.status?.toString().startsWith("5") ||
      data?.response?.code?.includes("ERR_BAD_RESPONSE")
    ) {
      return;
    }

    return true;
  };

  const { stepper, handleSteppers } = useSteppers();

  const redirectToExperimentEdit = (step: number) => {
    dataStopPublisExperimentValidation.mutate(experimentId, {
      onSuccess: (data) => {
        if (!isValidExperimentPublishResponse(data)) {
          handleToastConfig({
            message: "Unable to pause test version. Try again.",
            isOpened: true,
            type: TypeToast.ERROR,
          });

          closeDialog();
        } else {
          handleToastConfig({
            message: "Test version will be paused in up to 6 hours",
            isOpened: true,
            type: TypeToast.SUCCESS,
          });

          handleSteppers(step);
          closeDialog();

          navigate.goTo(`edit/${experimentId}`);
        }
      },
    });
  };

  const editExperiment = (step: number) => {
    if (experiment?.status === ExperimentStatusList.IN_VALIDATION) {
      handleStopPublishExperimentValidationViaEditLinkDialog(step);
      return;
    }
    handleSteppers(step);
    closeDialog();
    navigate.goTo(`edit/${experimentId}`);
  };

  const handleStopPublishExperimentValidationViaEditLinkDialog = async (
    step: number
  ) => {
    openDialog(
      "Edit experiment?",
      "",
      <Paragraph>
        <FormattedMessage id="experiment_details.modals.pause_experiment_via_edit_link_paragrapg" />
      </Paragraph>,
      null,
      null,
      [
        {
          action: () => {
            closeDialog();
          },
          dialogActionText: "No, cancel",
          dialogActionButtonType: "secondary",
        },
        {
          action: () => {
            redirectToExperimentEdit(step);
          },
          dialogActionText: "Yes, edit",
          dialogActionButtonType: "primary",
        },
      ],
      true,
      {
        width: "400px",
        fontFamily: "Work Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
      }
    );
    return;
  };

  const handleStopPublishExperimentValidationDialog = async () => {
    openDialog(
      "Pause test version?",
      "",
      <Paragraph>
        <FormattedMessage id="experiment_details.modals.pause_experiment_paragrapg" />
      </Paragraph>,
      null,
      null,
      [
        {
          action: () => {
            closeDialog();
          },
          dialogActionText: "No, cancel",
          dialogActionButtonType: "secondary",
        },
        {
          action: () => {
            handleStopPublisExperimentValidation();
          },
          dialogActionText: "Yes, pause",
          dialogActionButtonType: "primary",
        },
      ],
      true,
      {
        width: "400px",
        fontFamily: "Work Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
      }
    );
    return;
  };

  const ActionCards = {
    basicInformation: {
      content: "Edit",
      icon: <Edit2 size="large" />,
      actionFn: () => editExperiment(0),
    },
    audiences: {
      content: "Edit",
      icon: <Edit2 size="large" />,
      actionFn: () => editExperiment(1),
    },
    variants: {
      content: "Edit",
      icon: <Edit2 size="large" />,
      actionFn: () => editExperiment(2),
    },
  };

  const handleEditResult = () => {
    setEnterResultsOpen(true);
  };

  const handleStopPublisExperimentValidation = () => {
    dataStopPublisExperimentValidation.mutate(experimentId, {
      onSuccess: (data) => {
        if (!isValidExperimentPublishResponse(data)) {
          handleToast(
            "Unable to pause test version. Try again.",
            true,
            TypeToast.ERROR
          );
        } else {
          handleToast(
            "Test version will be paused in up to 6 hours",
            true,
            TypeToast.SUCCESS
          );
          refetchGetExperimentById();
        }
        closeDialog();
      },
    });
  };

  const handlePublishExperiment = () => {
    let toastConfigObject = {
      message: "",
      type: TypeToast.INFO,
    };

    setIsLoading(true);
    closeDialog();

    dataPublish.mutate(experimentId, {
      onSuccess: (data) => {
        if (!isValidExperimentPublishResponse(data)) {
          toastConfigObject = {
            message:
              data.response?.data?.errors[0]?.message ||
              "An error occurred while publishing the experiment.",
            type: TypeToast.ERROR,
          };
        } else {
          toastConfigObject = {
            message: "The experiment has been successfully published.",
            type: TypeToast.SUCCESS,
          };

          refetchGetExperimentById();
        }

        handleToast(toastConfigObject.message, true, toastConfigObject.type);
        setIsLoading(false);
      },
    });
  };

  const openPublishDialog = useCallback(() => {
    openDialog(
      "Publish experiment?",
      <div>
        <p>The experiment will be published to the selected audience.</p>
        <p>Make sure it has been appropriately tested before publishing.</p>
      </div>,
      null,
      {
        action: () => closeDialog(),
        dialogActionText: "No, Cancel",
        dialogActionButtonType: "secondary",
      },
      {
        action: () => {
          handlePublishExperiment();
        },
        dialogActionText: "Yes, Publish",
        dialogActionButtonType: "destructive",
      }
    );
  }, []);

  useEffect(() => {
    if (exportQuery.isSuccess) {
      generateJsonFile(exportQuery.data, exportQuery.data?.name);
      handleToast(
        "File successfully exported.",
        true,
        TypeToast.SUCCESS,
        SET_TIMEOUT_TOAST_DURATION
      );
      exportQuery.setExperimentId("");
    }
  }, [exportQuery.isSuccess]);

  const isAvailableToEdit =
    experiment?.status === ExperimentStatusList.DRAFT ||
    experiment?.status === ExperimentStatusList.IN_VALIDATION;

  useEffect(() => {
    if (!isValidExportResponse(exportQuery?.data)) {
      handleToast(
        "Unable to export file. Try again.",
        true,
        TypeToast.ERROR,
        SET_TIMEOUT_TOAST_DURATION
      );
      exportQuery.setExperimentId("");
    }
  }, [exportQuery.data]);

  useEffect(() => {
    return () => {
      dispatch(resetExperimentMutation());
    };
  }, []);

  return (
    <Item
      sm={12}
      md={12}
      lg={12}
      xl={12}
      xs={12}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "16px",
        paddingBottom: "16px",
        gap: "16px 0",
      }}
    >
      <EstimatedDateDialog
        refetchGetExperimentById={refetchGetExperimentById}
        experiment={experiment}
        estimatedEndDate={estimatedEndDate}
        setEstimatedEndDate={setEstimatedEndDate}
        isEstimatedEndDateDialogOpen={isEstimatedEndDateDialogOpen}
        setIsEstimatedEndDateDialogOpen={setIsEstimatedEndDateDialogOpen}
      />

      <EnterResultsDialog
        refetchGetExperimentById={refetchGetExperimentById}
        setEnterResultsOpen={setEnterResultsOpen}
        enterResultsOpen={enterResultsOpen}
        experiment={experiment}
      />

      <CustomDialog
        refetchGetExperimentById={refetchGetExperimentById}
        handleToast={handleToast}
        experimentId={experimentId}
        handleModal={handleModal}
        setHandleModal={setHandleModal}
      />

      <CustomToast
        dataTestid="custom-toast-dialog"
        isOpened={toast.isOpened}
        duration={toast.duration}
        onClose={handleCloseToast}
        type={toast.type}
        showCloseButton={true}
        message={toast.message}
      />

      {/* <CustomToast
        dataTestid="custom-toast-dialog"
        isOpened={toastConfig.isOpened}
        duration={toastConfig.duration}
        type={toastConfig.type}
        onClose={handleCloseToastConfig}
        showCloseButton={false}
        message={toastConfig.message}
      /> */}

      {/* {toastConfig} */}

      <ExportFileDialog
        open={exportQuery.isLoading}
        title={"exporting_file"}
        messages={["this_may_take_a_few_minutes", "do_not_close_this_window"]}
        close={handleCancelExportJSON}
      />

      <CustomPageHeader title={experiment?.name} actions={actionArea()} />
      <Item
        style={{ display: "flex", flexDirection: "column" }}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        xs={12}
        data-testid="step-4"
      >
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <ExperimentStatus
              experiment={experiment}
              actions={[
                {
                  action: handleStopPublishExperimentValidationDialog,
                  name: "Pause test",
                },
              ]}
            />
          </Card>
        </Item>
        {experiment && isNotItEmptyOrNull(experiment?.result) && (
          <Item sm={12} md={12} lg={12} xl={12} xs={12}>
            <Card
              ref={{
                current: "[Circular]" as any,
              }}
              border="medium"
              elevated="small"
              className="card"
            >
              <ExperimentResults
                action={{
                  content: "Edit",
                  icon: <Edit2 />,
                  actionFn: handleEditResult,
                }}
                experiment={experiment}
              />
            </Card>
          </Item>
        )}
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <ExperimentSchedule
              setIsEstimatedEndDateDialogOpen={setIsEstimatedEndDateDialogOpen}
              experiment={experiment}
            />
          </Card>
        </Item>
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <BasicInformation
              experiment={experiment}
              action={isAvailableToEdit && ActionCards.basicInformation}
            />
          </Card>
        </Item>
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular1]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <Metrics
              experiment={experiment}
              action={isAvailableToEdit && ActionCards.basicInformation}
            />
          </Card>
        </Item>
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <AudienceSelect
              experiment={experiment}
              action={isAvailableToEdit && ActionCards.audiences}
            />
          </Card>
        </Item>
        <Item sm={12} md={12} lg={12} xl={12} xs={12} style={{ padding: 0 }}>
          <Card
            ref={{
              current: "[Circular]" as any,
            }}
            border="medium"
            elevated="small"
            className="card"
            style={{ width: "100%" }}
          >
            <Variants
              experiment={experiment}
              action={isAvailableToEdit && ActionCards.variants}
            />
          </Card>
        </Item>
        <Item
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xs={12}
          style={{
            padding: 0,
            marginTop: 32,
            paddingBottom: 40,
            justifyContent: "end",
          }}
        >
          {renderPublishButton()}
        </Item>
      </Item>
    </Item>
  );
};

export default ExperimentDetails;
