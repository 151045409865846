import { useQuery } from "react-query";
import { getValidationAudiencePocIds } from "../../../../services/experiment/experimentService";

export const useGetValidationAudiencePocIdsQuery = () => {
  const query = useQuery({
    queryFn: () => getValidationAudiencePocIds(),
    queryKey: ["validationAudiencePocIds"],
    refetchOnWindowFocus: false,
    retry: 5,
  });

  return query;
};
