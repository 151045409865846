import { Container } from "@admin-portal-shared-components/grid";
import {
  Action,
  Badge,
  Grid,
  Heading,
  Paragraph,
  TextLink,
  Tooltip,
} from "@hexa-ui/components";
import { BarChart2, Copy, Info } from "@hexa-ui/icons";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Alerts from "../../../components/Alerts/Alerts";
import { handleCopyText } from "../../../pages/ExperimentMutation/utils";
import { getExperimentStatus } from "../../../services/utils";
import "../../../style.css";
import {
  ExperimentStatusList,
  ExperimentStatusProp,
  IExperiment,
} from "../../../types/experiment";
import { useEnvProvider } from "../../../utils/envProvider";
import { hasConfidenceLevelStatus } from "./../../../pages/ExperimentsList/utils/utils";
import { TooltipWrapper } from "./../../../pages/styles";
import { ExperimentDetailsContainer } from "./styles";

type ExperimentStatusProps = {
  experiment: IExperiment;
  actions?: [firstAction: Action, secondAction?: Action];
};

const ExperimentStatus = ({
  experiment,
  actions,
}: ExperimentStatusProps): JSX.Element => {
  const env = useEnvProvider();
  const metricReports = useMemo(
    () => env?.env?.metricReports,
    [env?.env?.metricReports]
  );
  const { Item } = Grid;
  const intl = useIntl();

  const getStatusBadge = (status: ExperimentStatusProp) => {
    if (status === undefined)
      return (
        <Badge.Status
          className="mt-1"
          key={"-"}
          color={"gray" as any}
          label={"-"}
        />
      );
    const statusValues = getExperimentStatus(status);

    return (
      <Badge.Status
        className="mt-1"
        key={statusValues.label}
        color={statusValues.color as any}
        label={`● ${intl.formatMessage({ id: statusValues.label })}`}
      />
    );
  };

  const [copied, setCopied] = useState("copy_external_key");

  const handleClipboard = (text: string) => {
    handleCopyText(text);
    setCopied("copied");
    setTimeout(() => {
      setCopied("copy_external_key");
    }, 3000);
  };

  const isInValidationStatus =
    experiment?.status === ExperimentStatusList.IN_VALIDATION;

  return (
    <ExperimentDetailsContainer style={{ width: "100%" }}>
      {isInValidationStatus && (
        <Container style={{ marginBottom: 32, marginTop: 0, width: "100%" }}>
          <Alerts
            type="info"
            message="This experiment's is in test version. You can either pause it or publish it to the entire selected audience."
            actions={actions}
          />
        </Container>
      )}
      <Container
        style={{ display: "flex", flexDirection: "row", width: "100%" }}
      >
        <Item lg={2} md={2} sm={12} xl={2} xs={12}>
          <div style={{ width: "100%" }}>
            <div>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.status.status" />
              </Heading>
              {getStatusBadge(experiment?.status as ExperimentStatusProp)}
            </div>
          </div>
        </Item>

        <Item lg={3} md={3} sm={12} xl={3} xs={12}>
          <div style={{ width: "100%" }}>
            <div>
              <div style={{ display: "flex" }}>
                <Heading
                  className="labelHeading"
                  size="H5"
                  style={{ display: "flex", position: "relative" }}
                >
                  <FormattedMessage id="experiment_details.status.confidence_level" />{" "}
                  <TooltipWrapper>
                    <Tooltip
                      text={intl.formatMessage({
                        id: "experiment_details.status.tooltip_text",
                      })}
                      placement="right"
                    >
                      <Info />
                    </Tooltip>
                  </TooltipWrapper>
                </Heading>
              </div>
              <Paragraph
                className="mt-1"
                data-testid="percentage-rollout-value"
              >
                {hasConfidenceLevelStatus(experiment?.status as string)
                  ? `${experiment?.confidenceLevel ?? 0}%`
                  : "-"}
              </Paragraph>
            </div>
          </div>
        </Item>

        <Item lg={5} md={5} sm={12} xl={5} xs={12}>
          <div style={{ width: "100%" }}>
            <div>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.external_key.label" />
              </Heading>
              <div
                className="mt-1"
                style={{
                  display: "inline-block",
                  position: "relative",
                }}
              >
                {experiment?.externalKey}
                <TooltipWrapper>
                  <Tooltip
                    text={intl.formatMessage({
                      id: `experiment_details.external_key.${copied}`,
                    })}
                    placement="top"
                  >
                    <Copy
                      onClick={() => handleClipboard(experiment?.externalKey)}
                      style={{ cursor: "pointer", color: "#0363c4" }}
                      size="large"
                    />
                  </Tooltip>
                </TooltipWrapper>
              </div>
            </div>
          </div>
        </Item>

        <Item lg={2} md={2} sm={12} xl={2} xs={12}>
          <div
            className="mt-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {experiment?.status !== "DRAFT" && (
              <TextLink
                href={`${metricReports.experiment}`}
                className="default-link medium-link mt-0"
                target="_blank"
                style={{ marginTop: "0px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BarChart2 size="large" />
                  <FormattedMessage id="experiment_details.status.go_to_reports" />
                </div>
              </TextLink>
            )}
          </div>
        </Item>
      </Container>
    </ExperimentDetailsContainer>
  );
};
export default ExperimentStatus;
