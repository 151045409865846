import { Paragraph } from "@hexa-ui/components";
import { TypeToast } from "admin-portal-shared-services";
import { FormattedMessage, useIntl } from "react-intl";
import * as Styles from "./styles";
const ExperimentValidationDialog = ({
  pocIds,
  handleToast,
}: CustomDialogProps) => {
  const Intl = useIntl();

  return (
    <>
      <Styles.DialogContentContainer>
        <Paragraph>
          <FormattedMessage
            id={`create_and_update_experiment.buttons.step4.modals.publish_experiment_validation_paragraph1`}
          />
        </Paragraph>
        <Styles.DialogPocIdsContainer>
          {pocIds?.map((pocId, index) => {
            return (
              <div>
                <span>
                  {pocId}
                  {index !== pocIds.length - 1 ? "," : "."}
                </span>
              </div>
            );
          })}
        </Styles.DialogPocIdsContainer>
        <Paragraph>
          <FormattedMessage
            id={`create_and_update_experiment.buttons.step4.modals.publish_experiment_validation_paragraph1`}
          />
        </Paragraph>
      </Styles.DialogContentContainer>
    </>
  );
};

type CustomDialogProps = {
  pocIds: string[];
  handleToast?: (
    message: string,
    isOpened: boolean,
    type?: TypeToast,
    duration?: number
  ) => void;
};

export default ExperimentValidationDialog;
